import './E404.scss';

const E404 = () => {
	return (
		<section id='e404'>
			<div className='e404__wrapper'>
				<h1>E404</h1>
			</div>
		</section>
	);
};

export default E404;
