import './Cart.scss';

const Cart = () => {
	return (
		<section id='cart'>
			<div className='cart__wrapper'>
				<h1>CART</h1>
			</div>
		</section>
	);
};

export default Cart;
