import './BrandAll.scss';

const BrandAll = () => {
	return (
		<section id='brand-all'>
			<div className='brandAll__wrapper'>
				<h1>ALL BRANDS</h1>
			</div>
		</section>
	);
};

export default BrandAll;
