import './Checkout.scss';

const Checkout = () => {
	return (
		<section id='checkout'>
			<div className='checkout__wrapper'>
				<h1>CHECKOUT</h1>
			</div>
		</section>
	);
};

export default Checkout;
