import './Register.scss';

const Register = () => {
	return (
		<section id='register'>
			<div className='register__wrapper'>
				<h1>REGISTER</h1>
			</div>
		</section>
	);
};

export default Register;
