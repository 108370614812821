import './Login.scss';

const Login = () => {
	return (
		<section id='login'>
			<div className='login__wrapper'>
				<h1>LOGIN</h1>
			</div>
		</section>
	);
};

export default Login;
